import React from 'react';
import { Link } from 'gatsby';

import pdf from '../assets/guides/Gogeta Bike - Employer Guide.pdf';
import image from '../assets/images/cycling-get-in-touch.png';
import { CustomButton } from '../components/custom-button';
import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { BikeCalculatorSection } from '../sections/EmployeeCycling/BikeCalculator';
import { InfoSection } from '../sections/EmployeeCycling/InfoSection';
import { BrownInfo } from '../sections/EmployerCycling/BrownInfo';
import { EmployerCyclingHero } from '../sections/EmployerCycling/EmployerCyclingHero';
import { HowItWorks } from '../sections/EmployerCycling/HowItWorks';
import { SignUp } from '../sections/EmployerCycling/SignUp';
import { download } from '../utils/download';

const EmployerCycling = () => {
	return (
		<MainLayout
			title="Employer Cycle to Work Benefits | Easy Setup & Staff Retention"
			description="Gogeta’s Cycle to Work scheme offers easy setup, minimal admin, and helps boost staff retention by saving employees money on new bikes through salary sacrifice."
		>
			<ScPage>
				<EmployerCyclingHero />
				<InfoSection
					title="Bigger slice for them, no cost to you"
					text="We can help make their salaries go further. Gogeta Bike gives employees the option to pay for a bike before they get taxed. Our flexi-voucher can be spent at thousands of retailers across the UK either all at once, or spread throughout the year. And it’s completely free for you to offer to staff."
				/>
				<BikeCalculatorSection title={'Try it for yourself'} isItEmployee={false} />
				<HowItWorks />
				<InfoSection
					title="FAQs"
					text="Got more questions? We’ve got you covered."
					buttons={
						<Link to="/employer-faq/">
							<CustomButton>Get some answers</CustomButton>
						</Link>
					}
				/>
				<BrownInfo
					title="Get in touch"
					text="We’d love to chat. If you’re an employer we can give you a quick walkthrough of how it all works, and how Gogeta can benefit your employees."
					image={image}
					link={'https://meetings-eu1.hubspot.com/barry2/c2w'}
					isBike={true}
				/>
				<InfoSection
					title="Want to find out more?"
					text="Browse our product guides for a bit more in depth info"
					buttons={
						<>
							<CustomButton
								onClick={() => {
									download(pdf as string);
								}}
							>
								Download this guide to Gogeta (Product)
							</CustomButton>
						</>
					}
				/>
				<SignUp isItBikeSite={true} />
			</ScPage>
		</MainLayout>
	);
};

export default EmployerCycling;
